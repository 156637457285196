import * as React from 'react';
import styled from '@emotion/styled';
import { useEditorHandlers } from './@editor';
import { useTranslation } from 'react-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { useAppContext } from '../../contexts/AppContext';
import Button from '@mui/material/Button';
import { Typography as MuiTypography } from '@mui/material';
import { Link as LinkComponent } from '../Link';
import { ImageLoaderWrapper } from '../ImageLoaderWrapper';
import Image, { ImageLoaderProps } from 'next/image';
import CnnMenuHardcoded from '../../components/Hardcoded/CnnMenuHardcoded';
import { useRouter } from 'next/router';

// icons"
import FacebookIcon from './icons/facebook';
import YoutubeIcon from './icons/youtube';
import TwitterIcon from './icons/twitter';
import InstagramIcon from './icons/instagram';

interface CategoryChunkProps {
  id: number;
  name: string;
  slug: string;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(17),
}));

const Root = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  color: theme.theme_component_footer_color || '#B0B1B7',
  backgroundColor: theme.theme_component_footer_background || '#000000',
  padding: theme.spacing(5),
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '& h3': {
    color: theme.theme_component_footer_h3_color || '#83ae30',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
    // display: 'none'
  }
}));

const GridItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
    justifySelf: 'stretch',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
    flexDirection: 'column',
    justifySelf: 'stretch',
  },
}));

const FooterNavMenu = styled('nav')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0, 4),
  '& a': {
    fontSize: '12px'
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    display: 'inline-block',
    textAlign: 'center',
    '& a': {
      margin: theme.spacing(1.5, 2),
      display: 'inline-block'
    },
  },
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '50px',
  margin: 'auto',
  [theme.breakpoints.up('lg')]: {
    margin: 'auto auto auto 0',
  },
})) as typeof Button;

const ImageLogo = styled(ImageLoaderWrapper)(() => ({
  display: 'block',
  width: '100%',
  height: 'auto',
})) as typeof ImageLoaderWrapper;


const AzerionBrandContainer = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
}));

const AzerionBrandContent = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
  marginLeft: 30,
  padding: '10px 0',
}));

const Title = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },

}));

const BrandTitle = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(1.5),
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center'
  }
}));

const Wrapper = styled('div')(() => ({
  maxWidth: '1365px',
  margin: 'auto'
}));

const BrandContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3)
}));


const BrandWrap = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center'
    }
}));
const SocialContainer = styled('div')(({ theme }) => ({
  '& a': {
    marginRight: theme.spacing(2),
  },

}));


const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) =>
  useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
);

export const FooterBarF = () => {
  const { t } = useTranslation('footer_bar_b');
  const appContext = useAppContext();
  const { url, assetUrl, footerLogoId, componentOptions } = appContext.useConfig();
  const { categories } = appContext.useCategories();
  const logoUrl = componentOptions.component_footer_bar_logo_url;
  const azerionBrand = componentOptions?.component_footer_bar_azerion_footer;
  const linkRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);
  const router = useRouter();
  const basePath = router.basePath || '';

  const youtubeUrl = componentOptions.component_footer_bar_youtube_url;
  const instagramUrl = componentOptions.component_footer_bar_instagram_url;
  const twitterUrl = componentOptions.component_footer_bar_twitter_url;
  const facebookUrl = componentOptions.component_footer_bar_facebook_url;

  // Todo: Fix linkRefs.current typescript problem.
  // @ts-ignore
  linkRefs.current = categories.map((category: CategoryChunkProps, index: number) => {
    // @ts-ignore
    return linkRefs.current[index] ?? React.createRef();
  });

  React.useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.innerHTML = `
      var ucLinkElement = document.querySelector('.footer__links .uclink');
      if(ucLinkElement) {
        if(window.WM && window.WM.UserConsent && window.WM.UserConsent.getLinkTitle().length !== 0) {
          ucLinkElement.textContent = window.WM.UserConsent.getLinkTitle();
          ucLinkElement.onclick = window.WM.UserConsent.getLinkAction();
          ucLinkElement.style.display = 'block';
          ucLinkElement.style.textAlign = 'center';
        } else {
          ucLinkElement.style.display = 'none';
        }
      }
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <Container>
        <Root {...useEditorHandlers('Footer Bar F')}>
      <Wrapper>
          <CnnMenuHardcoded />
          <Grid>
            <GridItem>
            {footerLogoId &&
                (logoUrl ? (
                  <ButtonLogo component={Link} href="https://cnn.com/" target="_blank" rel="noreferrer">
                    <ImageLogo
                      width={200}
                      height={60}
                      src={`${basePath}${assetUrl}${footerLogoId}/`}
                      alt={logoUrl}
                      sizes="200px"
                      layout="responsive"
                    />
                  </ButtonLogo>
                ) : (
                  <ButtonLogo component={Link} href="https://cnn.com/" aria-label="Home" title={url}>
                    <Image
                      loader={({ src }: ImageLoaderProps) => src}
                      layout="fill"
                      objectFit="contain"
                      unoptimized={true}
                      src={`${basePath}${assetUrl}${footerLogoId}`}
                      alt={url}
                    />
                  </ButtonLogo>
                ))}
            </GridItem>
            <GridItem>
              <Title>FOLLOW CNN</Title>
              <SocialContainer>
                {facebookUrl && (
                  <Link href={facebookUrl} target="_blank" rel="noreferrer" title="Facebook">
                    <FacebookIcon className={undefined} />
                  </Link>
                )}
                {twitterUrl && (
                  <Link href={twitterUrl} target="_blank" rel="noreferrer" title="Twitter">
                    <TwitterIcon />
                  </Link>
                )}
                {youtubeUrl && (
                  <Link href={youtubeUrl} target="_blank" rel="noreferrer" title="Youtube">
                    <YoutubeIcon className={undefined} />
                  </Link>
                )}
                {instagramUrl && (
                  <Link href={instagramUrl} target="_blank" rel="noreferrer" title="Instagram">
                    <InstagramIcon className={undefined} />
                  </Link>
                )}
              </SocialContainer>
            </GridItem>
          </Grid>
          <FooterNavMenu className="footer__links" data-editable="footerLinks">
            <Link href="https://www.cnn.com/terms"  className="footer__link">Terms of Use</Link>
            <Link href="https://www.cnn.com/privacy" className="footer__link">Privacy Policy</Link>
            <Link href="#" className="uclink footer__link"></Link>
            <Link href="https://www.cnn.com/ad-choices" target="_blank" className="footer__link">Ad Choices</Link>
            <Link href="https://www.cnn.com/accessibility" className="footer__link">Accessibility &amp; CC</Link>
            <Link href="https://www.cnn.com/about" className="footer__link">About</Link>
            <Link href="https://www.cnn.com/newsletters" className="footer__link">Newsletters</Link>
            <Link href="https://www.cnn.com/transcripts" className="footer__link">Transcripts</Link>
          </FooterNavMenu>
          <BrandContainer>
            <BrandTitle>
              © 2024 Cable News Network. A Warner Bros. Discovery Company. All Rights Reserved.
            </BrandTitle>
            <BrandWrap>
              <BrandTitle>
                CNN Sans ™ & © 2016 Cable News Network.
              </BrandTitle>
            </BrandWrap>
          </BrandContainer>
      </Wrapper>

        </Root>
        
        {azerionBrand && (
          <AzerionBrandContainer>
            <AzerionBrandContent>
              <Image
                src="/images/azerion-brand.png"
                loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
                alt="made-with-love"
                height="30"
                width="180"
              />
            </AzerionBrandContent>
          </AzerionBrandContainer>
        )}
    </Container>
  );
};
